import {useTranslation} from 'react-i18next';
import {Typography} from '../../Common/Typography';
import EventCountdown from './EventCountdown';
import {useHistory} from 'react-router';
import {ROUTES} from '../../../constants/routes';
import {useSelector} from 'react-redux';

function EventHeaderContent({timeLeft, title, eventForm}) {
	const {t} = useTranslation();
	const history = useHistory();
	const eventInvitations = useSelector((state) => state.events.eventInvitations);
	const multipleInvitations = eventInvitations?.length > 1;

	function redirectToForm() {
		history.push(ROUTES.FORM_DETAILS, {eventForm});
	}

	function redirectToInvitations() {
		history.push(ROUTES.INVITATIONS);
	}

	return (
		<div className="event-countdown-content">
			<div className="event-info">
				<Typography variant="display-small">{title}</Typography>
				{/* <Typography variant="title-small">{t('event.subTitle')}</Typography> */}
			</div>
			<EventCountdown timeLeft={timeLeft} title={<Typography variant="title-small starts-in">{t('event.startsIn')}</Typography>} />
			{eventForm && (
				<span className="change-registration" onClick={redirectToForm}>
					{t('event.changeRegistration')}
				</span>
			)}
			{multipleInvitations && (
				<span className="change-registration" onClick={redirectToInvitations}>
					{t('event.backToInvitations')}
				</span>
			)}
		</div>
	);
}

export default EventHeaderContent;
